@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.loadleft {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 400ms ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0);
  }
}

.loadright {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 400ms ease-out 0s 1 slideInFromRight;
}

.sidebar {
  backdrop-filter: blur(30px) saturate(2);
  -webkit-backdrop-filter: blur(30px) saturate(2);
  padding-inline: 0;
  padding-block: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 2.2s cubic-bezier(0.36, -0.01, 0, 0.77);
  height: 100%;
}

@keyframes scaleBox {
  from {
    transform: scale(0.4, 0.4);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes scaleHeight {
  from {
    transform: scale(1, 0.4);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes scaleWidth {
  from {
    transform: scale(0.5, 1);
  }
  to {
    transform: scale(1, 1);
  }
}

.popup {
  animation-name: scaleBox;
  animation-duration: 150ms;
}

.expand {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* transition-duration: 150ms; */
  animation-name: scaleHeight;
  animation-duration: 150ms;
}

.expand-horizontal {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* transition-duration: 150ms; */
  animation-name: scaleWidth;
  animation-duration: 150ms;
}
