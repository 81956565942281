.booking-bar-shadow {
  box-shadow: 0px 4px 20px 0px #00000040;
}
.heading-gradient {
  background: linear-gradient(180deg, #0caace 0%, #065668 88.11%);
}

.bookings-items {
  height: 5.5rem;
  border: 0.5px solid #06566866;
  border-radius: 10px;
  background: #0000000d;
}
